import { useEffect, useState } from "react";
import { OutOfCreditsKa } from "./out-of-credits-ka";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  AICreditDisplay,
  formatDurationRemainingToCreditsRenewal,
  getMissingTimeToCreditsRenewal,
} from "../components/folder/ai-credits";
import { CoinsIcon, LockKeyholeIcon, XIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import { getAvailableRewardsClient, RewardAction } from "@/lib/kv-utils/utils";
import { LightningBoltIcon } from "@radix-ui/react-icons";
import { useUser } from "@clerk/react-router";
import { Link } from "react-router";
import { usePostHog } from "posthog-js/react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const REWARDS_DESCRIPTIONS: Record<RewardAction, string> = {
  "study-flashcard-reward": "study 10 flaschards",
  "do-quiz-reward": "do one quiz",
  "share-deck-reward": "share one of your decks",
};

const noRewardText = (
  <>
    Come back <b>tomorrow</b> or <b>upgrade</b> your plan
  </>
);

export const OutOfCreditsDialog = ({
  isOpen,
  setIsOpen,
  onRewardTaskAccept,
  onUpgradePressed,
  onWaitPressed,
  showAmbassadorDialog,
  showDiscountDialog,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onRewardTaskAccept?: (reward: RewardAction) => void;
  onUpgradePressed?: () => void;
  onWaitPressed?: () => void;
  showAmbassadorDialog: boolean;
  showDiscountDialog: boolean;
}) => {
  const [showIncreaseBar, setShowIncreaseBar] = useState(false);
  const posthog = usePostHog();

  const userInfo = useUser();
  const isVeteran = userInfo.user?.publicMetadata["status"] === "veteran";
  const isMaster = userInfo.user?.publicMetadata["status"] === "master";
  const isWizard = userInfo.user?.publicMetadata["status"] === "wizard";
  const isBeast = userInfo.user?.publicMetadata["status"] === "beast";
  const hasLimitedCredits = !(isWizard || isMaster || isVeteran);

  const { data: rewards, isSuccess } = useQuery({
    queryKey: ["available-rewards"],
    queryFn: async () => {
      const data = await getAvailableRewardsClient();

      return data;
    },
    enabled: hasLimitedCredits && !!userInfo && !!userInfo.user,
  });

  let calculatedNextReward:
    | { reward: RewardAction; credits: number }
    | undefined;
  if (isSuccess && hasLimitedCredits) {
    calculatedNextReward = Object.entries(rewards.availableRewards)
      .map<{ reward: RewardAction; credits: number }>(
        ([reward, { credits }]) => {
          return { reward: reward as RewardAction, credits: credits };
        }
      )
      // Remove do quiz reward since it requires credits to be completed
      .filter(({ reward }) => reward !== "do-quiz-reward")
      .find(({ credits }) => credits > 0);
  }

  const nextReward = calculatedNextReward;

  useEffect(() => {
    if (!showIncreaseBar) {
      const timeout = setTimeout(() => {
        setShowIncreaseBar(true);
      }, 1500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showIncreaseBar, setShowIncreaseBar]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(isOpen) => {
        // Don't allow the closing option from this dialog but from the X below
        if (showAmbassadorDialog) {
          return;
        }

        if (!isOpen) {
          setIsOpen(false);
        }
      }}
    >
      <DialogContent
        className="max-w-[90vw] md:max-w-2xl lg:max-w-3xl"
        closeButton={
          showAmbassadorDialog ? (
            <Button
              variant="strokeSecondary"
              size="iconMd"
              onClick={() => {
                posthog.capture(
                  "Dialog-Out-Of-Credits-Ambassador-Close-Clicked"
                );
                setIsOpen(false);
              }}
            >
              <XIcon />
            </Button>
          ) : undefined
        }
      >
        {showDiscountDialog ? (
          <div>
            <DiscountDialog
              clickOnDecline={() => {
                posthog.capture(
                  "Dialog-Out-Of-Credits-Discount-Decline-Clicked"
                );
                setIsOpen(false);
              }}
              clickOnUpgrade={() => {
                posthog.capture(
                  "Dialog-Out-Of-Credits-Discount-Upgrade-Clicked"
                );
                onUpgradePressed?.();
              }}
            />
          </div>
        ) : showAmbassadorDialog ? (
          <div>
            <Ambassador
              clickOnUpgrade={() => {
                posthog.capture(
                  "Dialog-Out-Of-Credits-Ambassador-Upgrade-Clicked"
                );
                onUpgradePressed?.();
              }}
            />
          </div>
        ) : (
          <div>
            <div className="flex flex-col items-center justify-center gap-6">
              <OutOfCreditsKa />
              <div className="flex flex-col items-center justify-center py-6 gap-4">
                <h2 className="inline text-secondary-950 text-[28px] font-bold font-['Mona Sans']">
                  Oh no... You are out of AI Credits
                </h2>
                <span className="text-secondary-600 text-base font-medium font-['Mona Sans'] break-words">
                  {nextReward ? (
                    <>
                      Come back <b>tomorrow</b>, <b>upgrade</b> your plan or{" "}
                      <b>{REWARDS_DESCRIPTIONS[nextReward.reward]}</b> to earn
                      extra credits👇
                    </>
                  ) : (
                    <>{noRewardText}</>
                  )}
                </span>
              </div>
              <div className="flex flex-col-reverse md:flex-row gap-3 w-full items-end">
                <Button
                  variant="secondary"
                  className="w-full flex-1 px-4 py-2"
                  onClick={() => {
                    onWaitPressed?.();
                  }}
                >
                  <LockKeyholeIcon className="h-5 w-5 mr-2" /> Wait
                  <b className="ml-1">
                    {formatDurationRemainingToCreditsRenewal(
                      getMissingTimeToCreditsRenewal(isBeast),
                      2
                    )}
                  </b>
                </Button>
                <Button
                  variant={nextReward ? "secondary" : "primary"}
                  className="w-full flex-1 px-4 py-2"
                  onClick={() => {
                    onUpgradePressed?.();
                  }}
                >
                  <LightningBoltIcon className="h-5 w-5 mr-2" /> Upgrade
                </Button>
                {nextReward ? (
                  <div className="flex w-full flex-col flex-1">
                    <AICreditDisplay
                      isCollapsed={false}
                      isShowWhenPremium={false}
                      showUpgradeButton={false}
                      creditsIncrease={showIncreaseBar ? nextReward.credits : 0}
                    />
                    <Button
                      variant="primary"
                      className={cn("flex-1 px-4 py-2")}
                      onClick={() => {
                        onRewardTaskAccept?.(nextReward.reward);
                      }}
                    >
                      <>
                        <CoinsIcon className="me-2" /> Earn
                        <b className="ms-1">+{nextReward.credits} Credits</b>
                      </>
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const Ambassador = ({ clickOnUpgrade }: { clickOnUpgrade: () => void }) => {
  const posthog = usePostHog();
  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <img
        src="/images/ambassador.png"
        width={451}
        height={244}
        alt="ambassador program"
      />
      <div className="flex flex-col items-center justify-center py-6 gap-4 text-center">
        <h2 className="inline text-secondary-950 text-[28px] font-bold font-['Mona Sans']">
          Want Premium Access?
        </h2>
        <span className="text-secondary-600 text-base text-center">
          Become a <span className="font-medium">Flashka Ambassador!</span>
          <br />
          Support us 5 minutes a day to gain Flashka Wizard Plan
          <br />
          and special rewards!
        </span>
      </div>
      <div className="flex flex-col-reverse md:flex-row gap-3 w-full items-end">
        <Button
          variant="secondary"
          className="w-full flex-1 px-4 py-2"
          onClick={() => {
            clickOnUpgrade();
          }}
        >
          <LightningBoltIcon className="h-5 w-5 mr-2" /> Upgrade
        </Button>
        <Link
          to="https://flashka.notion.site/Want-Flashka-For-free-18f13db82562806cb417cc99d79a82d6"
          target="_blank"
          rel="noopener noreferrer"
          className="w-full flex-1"
          onClick={() => {
            posthog.capture(
              "Dialog-Out-Of-Credits-Ambassador-Free-Premium-Clicked"
            );
          }}
        >
          <Button variant="primary" className="w-full px-4 py-2">
            <CoinsIcon className="h-5 w-5 mr-2" /> Get Premium for FREE
          </Button>
        </Link>
      </div>
    </div>
  );
};

const DiscountDialog = ({
  clickOnDecline,
  clickOnUpgrade,
}: {
  clickOnDecline: () => void;
  clickOnUpgrade: () => void;
}) => {
  const posthog = usePostHog();
  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <DotLottieReact
        src="https://lottie.host/d33a1d4d-8003-4b82-b1db-eab559a4ee5a/DrXAhQBDxR.lottie"
        autoplay={true}
        loop={true}
        style={{ width: "100%", height: "100%" }}
        className="-mb-6"
      />
      <div className="flex flex-col items-center justify-center py-6 gap-4 text-center">
        <h2 className="inline text-secondary-950 text-[28px] font-bold font-['Mona Sans']">
          Get 50% Off now!
        </h2>
        <span className="text-secondary-600 text-base text-center">
          You’ve unlocked a personal promo! 💜
          <br />
          Choose a premium plan,{" "}
          <span className="font-medium">
            apply the code 50SPECIAL at checkout
          </span>
          ,<br /> and enjoy your discount!
        </span>
      </div>
      <div className="flex flex-col-reverse md:flex-row gap-3 w-full items-end">
        <Button
          variant="secondary"
          className="w-full flex-1 px-4 py-2"
          onClick={() => {
            clickOnDecline();
          }}
        >
          <XIcon className="h-5 w-5 mr-1" /> Decline Offer
        </Button>
        <Button
          variant="primary"
          className="w-full flex-1 px-4 py-2"
          onClick={() => {
            posthog.capture("Dialog-Out-Of-Credits-Discount-Premium-Clicked");
            clickOnUpgrade();
          }}
        >
          <LightningBoltIcon className="h-5 w-5 mr-1" /> Get 50% Off with code
          <b>50SPECIAL</b>
        </Button>
      </div>
    </div>
  );
};
