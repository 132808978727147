import { Button } from "@/components/ui/button";
import { useUser } from "@clerk/react-router";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { Link } from "react-router";

const UpgradeSuccessPage = () => {
  const posthog = usePostHog();
  const userInfo = useUser();
  const isUnlimited =
    userInfo.user?.publicMetadata["status"] === "veteran" ||
    userInfo.user?.publicMetadata["status"] === "wizard" ||
    userInfo.user?.publicMetadata["status"] === "veteran";

  useEffect(() => {
    if (userInfo.user?.publicMetadata["status"]) {
      posthog.capture("upgrade-successful", {
        status: userInfo.user?.publicMetadata["status"],
        subscriptionLenght:
          userInfo.user?.publicMetadata["subscriptionLenght"] || "not-set",
      });
    }

    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "upgrade-successful",
      });
    } else {
      console.warn("dataLayer is not defined on window");
    }
  }, [userInfo.user?.publicMetadata["status"]]);

  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen bg-white text-center relative">
        <div className="text-center absolute top-12 z-50">
          <h1 className="text-6xl font-bold mb-4">
            Thank <span className="text-violet-300">you!</span>
          </h1>
          <p className="mb-6">Now you are a Flashka Master!</p>
          <ul className="list-none mb-6 mt-12">
            <li className="flex items-center mb-4">
              <div className="bg-green-300 rounded-full h-6 w-6 mr-2"></div>
              {isUnlimited ? "Unlimited" : 3000} AI credits
            </li>
            <li className="flex items-center mb-4">
              <div className="bg-green-300 rounded-full h-6 w-6 mr-2"></div>{" "}
              Unlimited space repetition studying
            </li>
            <li className="flex items-center">
              <div className="bg-green-300 rounded-full h-6 w-6 mr-2"></div>
              Support the development of Flashka
            </li>
          </ul>
        </div>
        <Link to="/" className="z-50">
          <Button variant="primary">Go to home</Button>
        </Link>
        <div className="absolute mb-48 bottom-0 z-0 pointer-events-none">
          <svg
            width="1391"
            height="803"
            viewBox="0 0 1391 803"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M647 756.071C632.667 735.071 556.824 650.21 450.5 636.071C356.5 623.57 250 537.197 250 442.071C250 195.071 435.582 125.309 310.5 48.0713C219 -8.4292 40 28.0713 0 12.0713L533 0.571095C509.081 21.6566 519 48.0713 457 59.5713C395 71.0713 419.833 175.629 430.098 249.22C432.07 263.354 433.872 276.277 435 287.071C438.087 316.619 419.975 326.912 397.86 339.48C369.83 355.409 335.369 374.993 329.5 442.071C321.639 531.913 374.035 550.849 458.991 581.551C487.508 591.856 519.694 603.488 554.5 619.571C665.3 670.771 686.333 762.905 682.999 802.571H647V756.071Z"
              fill="#FEF3C7"
              fillOpacity="0.5"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1246.31 15.8044C1205.47 14.893 1183.17 14.3952 1166.81 16.6865L1343.23 17.3843C1302.33 17.055 1270.95 16.3544 1246.31 15.8044ZM1343.23 17.3843C1357.75 17.5012 1373.46 17.5712 1390.5 17.5712L1343.23 17.3843Z"
              fill="#FEF3C7"
              fillOpacity="0.5"
            />
            <path
              d="M718.999 756.071V802.571H691.5C689 762.738 695.7 678.372 742.5 659.571C801 636.071 909 627.571 926 539.571C943 451.571 909.068 415.037 961.5 339.48C988.5 300.571 1028.5 247.571 996.5 162.571C976.513 109.48 973.664 55.2179 990.807 15.9903L1166.81 16.6865C1147.11 19.4439 1136.02 26.2405 1111.57 41.2163C1103.23 46.3271 1093.33 52.3906 1081 59.5713C1027.7 90.6193 1053.53 208.426 1076.87 314.887C1098.63 414.161 1074.35 473.071 1036.5 509.571C998.648 546.071 977.918 550.748 937.699 597.715C898.556 643.426 859.583 688.939 811 690.571C780.832 690.904 720.197 704.471 718.999 756.071Z"
              fill="#FEF3C7"
              fillOpacity="0.5"
            />
          </svg>
        </div>
        <div className="mb-12 absolute bottom-0 z-0">
          <svg
            width="392"
            height="188"
            viewBox="0 0 392 188"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M196 251C304.248 251 392 226.376 392 196C392 165.624 304.248 141 196 141C87.7522 141 0 165.624 0 196C0 226.376 87.7522 251 196 251Z"
              fill="#2E1065"
            />
            <path
              d="M267.113 41.5625C292.161 41.5625 312.467 61.8683 312.467 86.9166C312.467 111.965 292.161 132.271 267.113 132.271"
              stroke="#2E1065"
              strokeWidth="6"
              strokeLinejoin="round"
            />
            <path
              d="M251.103 3H136.443C127.607 3 120.443 10.1634 120.443 19V154.857C120.443 163.693 127.607 170.857 136.443 170.857H251.103C259.94 170.857 267.103 163.693 267.103 154.857V19C267.103 10.1634 259.94 3 251.103 3Z"
              fill="#A78BFA"
              stroke="#2E1065"
              strokeWidth="6"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M185.976 54.7573L170.193 90.0682H185.976L172.724 124.644L193.979 83.348H180.18L193.979 54.7573H185.976Z"
              fill="#2E1065"
            />
            <path
              d="M203.411 78.0837L218.8 54.7598H193.972L180.172 83.3505H193.972L172.717 124.646L211.02 78.0837H203.411Z"
              fill="#FEF3C7"
            />
            <path
              d="M170.342 128.269L211.62 78.099H203.413L219.999 52.9624H193.255M170.359 128.269L184.637 91.0092H167.623L184.637 52.9624H193.273M170.376 128.269L193.273 83.759H178.396L193.273 52.9453"
              stroke="#2E1065"
              strokeWidth="6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default UpgradeSuccessPage;
