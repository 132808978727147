import { Dialog, DialogContent } from "@/components/ui/dialog";
import React, { createContext, useContext, useState } from "react";
import { SubscriptionOptions } from "./components";
import { OutOfCreditsDialog } from "./out-of-credits-dialog";
import { useLocation, useNavigate, useSearchParams } from "react-router";
import { acceptTaskAction } from "@/components/rewarding-system/reward-task-manager";
import { usePostHog } from "posthog-js/react";
import { useLocalStorage } from "@/components/hooks/use-local-storage";
import { useAuth } from "@clerk/react-router";

interface PaymentDialogContextType {
  showPaymentDialog: (
    value?: boolean | "feature-upgrade" | "outOfCredits",
    consecutiveDays?: number
  ) => void;
}

const PaymentDialogContext = createContext<
  PaymentDialogContextType | undefined
>(undefined);

export type UpgradeDialogState = "closed" | "outOfCredits" | "plans";

function maybeGetDeckNameFromPath(
  path: string,
  searchParams: ReturnType<typeof useSearchParams>[0]
) {
  const pathSegments = path.split("/");

  if (pathSegments[1] === "deck" || pathSegments[1] === "quiz") {
    const subpathName = path.split("/")[2];
    if (subpathName) {
      return decodeURIComponent(subpathName);
    }
  }

  if (path.includes("memo-ai")) {
    const deck = searchParams.get("deck");
    if (deck) {
      return decodeURIComponent(deck);
    }
  }

  return null;
}

export const UpgradeProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [dialogState, setDialogState] = useState<UpgradeDialogState>("closed");
  const [consecutiveDays, setConsecutiveDays] = useState<number>(0);
  const posthog = usePostHog();

  const { userId } = useAuth();
  const [userInfo] = useLocalStorage<Record<string, any>>("user-info", {});
  const isHighSchooler = userInfo[userId || ""]?.fieldOfStudy === "high-school";

  const showPaymentDialog = (
    value?: "outOfCredits" | "feature-upgrade" | boolean,
    consecutiveDays?: number
  ) => {
    if (value === true || value === "outOfCredits" || value === undefined) {
      posthog.capture("Show-Dialog-Out-Of-Credits");
      setDialogState("outOfCredits");
      if (consecutiveDays) {
        if (isHighSchooler) {
          posthog.capture("Show-Dialog-Out-Of-Credits-Ambassador", {
            consecutiveDaysNoCredits: consecutiveDays,
          });
        }
        setConsecutiveDays(consecutiveDays);
      }
    } else if (value === false) {
      setDialogState("closed");
    } else if (value === "feature-upgrade") {
      setDialogState("plans");
    }
  };

  return (
    <PaymentDialogContext.Provider value={{ showPaymentDialog }}>
      {children}
      <OutOfCreditsDialogWrapper
        isOpen={dialogState === "outOfCredits"}
        setDialogState={setDialogState}
        showAmbassadorDialog={isHighSchooler && consecutiveDays >= 1}
        showDiscountDialog={consecutiveDays >= 3}
      />
      <Dialog
        open={dialogState === "plans"}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setDialogState("closed");
            posthog.capture("Close-Upgrade-Modal");
          }
        }}
      >
        <DialogContent className="md:min-w-[90vw] overflow-scroll max-h-[85vh] flex flex-col items-center">
          <div className="text-center text-4xl font-bold text-title-950 w-full">
            Unlock your{" "}
            <span className="text-primary-500">full potential 🥇</span>
          </div>
          <div className="font-medium max-w-xl text-subtitle-800 text-center text-base w-full ">
            Forget endless flashcard writing. Focus on learnign while the AI
            does the rest for you. Upgrade Flashka now and start studying
            effectively!
          </div>
          <SubscriptionOptions />
        </DialogContent>
      </Dialog>
    </PaymentDialogContext.Provider>
  );
};

interface OutOfCreditsDialogWrapperProps {
  isOpen: boolean;
  showAmbassadorDialog: boolean;
  showDiscountDialog: boolean;
  setDialogState: (state: "closed" | "outOfCredits" | "plans") => void;
}

export function OutOfCreditsDialogWrapper({
  isOpen,
  setDialogState,
  showAmbassadorDialog,
  showDiscountDialog,
}: OutOfCreditsDialogWrapperProps) {
  const navigate = useNavigate();
  const pathName = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const posthog = usePostHog();

  const deckName = maybeGetDeckNameFromPath(pathName, searchParams);

  return (
    <OutOfCreditsDialog
      showDiscountDialog={showDiscountDialog}
      isOpen={isOpen}
      showAmbassadorDialog={showAmbassadorDialog}
      setIsOpen={(isOpen) => {
        setDialogState(isOpen ? "outOfCredits" : "closed");
      }}
      onRewardTaskAccept={(reward) => {
        posthog.capture("Out-Of-Credits-reward-task-accept");
        acceptTaskAction(navigate, reward, deckName ?? undefined);
        setDialogState("closed");
      }}
      onUpgradePressed={() => {
        posthog.capture("Out-Of-Credits-upgrade");
        setDialogState("plans");
      }}
      onWaitPressed={() => {
        posthog.capture("Out-Of-Credits-wait");
        setDialogState("closed");
      }}
    />
  );
}

export const useUpgradeDialog = () => {
  const context = useContext(PaymentDialogContext);
  if (context === undefined) {
    throw new Error(
      "usePaymentDialog must be used within a PaymentDialogProvider"
    );
  }
  return context;
};
