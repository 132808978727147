import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router";
import React, { Suspense, useEffect, useState } from "react";

import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://894473f8f6b68ed272497ab691a5ed7f@o4505868160073728.ingest.us.sentry.io/4509037462487040",
  integrations: [
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ThemeProvider } from "./settings/theme";
import { NavBar } from "./components/nav-bar";
import { ClerkProvider, SignIn, useAuth } from "@clerk/react-router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PHProvider } from "./providers/posthog";
import { UpgradeProvider } from "./upgrade/upgrade-dialog";
import { ErrorBoundary } from "./Error";
import { SidebarWrapper } from "./components/sidebar";
import UpgradePage from "./upgrade/page";
import { CurrencyProvider } from "./contexts/currency-context";
import { Toaster } from "@/components/ui/toaster";
import { ToasterSonner } from "./components/ui/sonner";
import UpgradeSuccessPage from "./pages/upgrade-success";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { cn } from "./lib/utils";
import { Metadata } from "./metadata/context";

// import Dashboard from "./pages/dashboard";
// import HomePage from "./pages/home";
// import DeckPage from "./pages/deck";
// import AddPage from "./pages/add";
// import MemoAIPage from "./pages/memo-ai";
// import NotFoundPage from "./pages/not-found";
// import SettingsPage from "./pages/settings";
// import BlogHomePage from "./pages/blog-home";
// import BlogPostPage from "./pages/blog-post";
// import BlogTopicsPage from "./pages/blog-topics";
// import TopicPage from "./pages/topic";
// import HelpHomePage from "./pages/help-home";
// import HelpArticlePage from "./pages/help-article";
// import TermsPage from "./pages/terms";
// import LegalPage from "./pages/legal";
// import PrivacyPage from "./pages/privacy";
// import DownloadPage from "./pages/download";
// import MemoPage from "./pages/memo";
// import QuizPage from "./pages/quiz";
// // import QuizSharedPage from "";
// const QuizSharedPage = React.lazy(() => import("./pages/quiz-shared"));

// Removing temporarily for safari issue?
// import loadable from "@loadable/component";
const Dashboard = React.lazy(() => import("./pages/dashboard"));
const HomePage = React.lazy(() => import("./pages/home"));
const DeckPage = React.lazy(() => import("./pages/deck"));
const AddPage = React.lazy(() => import("./pages/add"));
const MemoAIPage = React.lazy(() => import("./pages/memo-ai"));
const NotFoundPage = React.lazy(() => import("./pages/not-found"));
const SettingsPage = React.lazy(() => import("./pages/settings"));
const BlogHomePage = React.lazy(() => import("./pages/blog-home"));
const BlogPostPage = React.lazy(() => import("./pages/blog-post"));
const BlogTopicsPage = React.lazy(() => import("./pages/blog-topics"));
const TopicPage = React.lazy(() => import("./pages/topic"));
const HelpHomePage = React.lazy(() => import("./pages/help-home"));
const HelpArticlePage = React.lazy(() => import("./pages/help-article"));
const TermsPage = React.lazy(() => import("./pages/terms"));
const LegalPage = React.lazy(() => import("./pages/legal"));
const PrivacyPage = React.lazy(() => import("./pages/privacy"));
const DownloadPage = React.lazy(() => import("./pages/download"));
const MemoPage = React.lazy(() => import("./pages/memo"));
const QuizPage = React.lazy(() => import("./pages/quiz"));
const QuizSharedPage = React.lazy(() => import("./pages/quiz-shared"));

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <NavBar />
      <Toaster />
      <ToasterSonner
        toastOptions={{
          classNames: {
            error: "bg-alert-50 border-alert-300 text-alert-950",
            success: "bg-valid-200 text-valid-950 border-valid-300 ",
          },
        }}
      />
      <UpgradeProvider>
        <SidebarWrapper>{children}</SidebarWrapper>
      </UpgradeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

const queryClient = new QueryClient();
function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <CurrencyProvider>
            <Routing />
          </CurrencyProvider>
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P59TXPCV" height="0" width="0" style="display: none; visibility: hidden;"></iframe>`,
            }}
          />
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

window.addEventListener("vite:preloadError", (event) => {
  // window.location.reload() // We will consider doing this!
  console.error("A vite preloadError has occurred: " + JSON.stringify(event));
});

const Routing = () => {
  return (
    <BrowserRouter>
      {/* <MetadataDefault /> */}
      <Metadata>
        <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
          <PHProvider>
            <Layout>
              <ErrorBoundary>
                <Suspense
                  fallback={
                    <div
                      className={cn(
                        "w-full h-full flex justify-center items-center mt-10"
                      )}
                    >
                      <DotLottieReact
                        src="https://lottie.host/22bd1c95-01ac-4ae1-b928-d2cef47779d3/d5DqnmxmCw.lottie"
                        autoplay
                        loop
                        className="max-h-64 max-w-[500px]"
                      />
                    </div>
                  }
                >
                  <SentryRoutes>
                    <Route path="/" element={<RootRedirect />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route
                      path="/welcome"
                      element={<WelcomeRedirect type="welcome" />}
                    />
                    <Route
                      path="/welcome-back"
                      element={<WelcomeRedirect type="welcome-back" />}
                    />
                    <Route
                      path="/dashboard"
                      element={
                        <ProtectedRoute>
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/deck/:deckName" element={<DeckPage />} />
                    <Route
                      path="/add"
                      element={
                        <ProtectedRoute>
                          <AddPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/memo-ai"
                      element={
                        <ProtectedRoute>
                          <MemoAIPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/upgrade"
                      element={
                        <ProtectedRoute>
                          <UpgradePage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/upgrade-success"
                      element={
                        <ProtectedRoute>
                          <UpgradeSuccessPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/blog" element={<BlogHomePage />} />
                    <Route path="/blog/:postId" element={<BlogPostPage />} />
                    <Route path="/blog/topics" element={<BlogTopicsPage />} />
                    <Route
                      path="/blog/topic/:topicId"
                      element={<TopicPage />}
                    />
                    <Route path="/help" element={<HelpHomePage />} />
                    <Route
                      path="/help/:articleId"
                      element={<HelpArticlePage />}
                    />
                    <Route
                      path="/quiz/:deckName"
                      element={
                        <ProtectedRoute>
                          <QuizPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/quiz/:deckName/:quizId"
                      element={<QuizSharedPage />}
                    />
                    <Route
                      path="/settings"
                      element={
                        <ProtectedRoute>
                          <SettingsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/legal" element={<LegalPage />} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route path="/download/:url" element={<DownloadPage />} />
                    <Route
                      path="/memo"
                      element={
                        <ProtectedRoute>
                          <MemoPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/social/:platform"
                      element={<SocialRedirectRoute />}
                    />
                    {/* This should always be the last route */}
                    <Route path="*" element={<NotFoundPage />} />
                  </SentryRoutes>
                </Suspense>
              </ErrorBoundary>
            </Layout>
          </PHProvider>
        </ClerkProvider>
      </Metadata>
    </BrowserRouter>
  );
};

export function MetadataDefault() {
  const BASE_URL = `${window.location.protocol}//${window.location.host}`;
  return (
    <>
      {/* Basic metadata */}
      <title>Flashka - Learn faster with AI Flashcards!</title>
      <meta
        name="description"
        content="Flashka helps you learn by automatically generating flashcards and aiding you in the learning process."
      />

      {/* Base URL */}
      <base href={BASE_URL} />

      {/* OpenGraph metadata */}
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Flashka - Learn faster with AI Flashcards!"
      />
      <meta
        property="og:description"
        content="Flashka helps you learn by automatically generating flashcards and aiding you in the learning process."
      />
      <meta property="og:image" content="/social-thumbnail.png" />

      {/* Twitter metadata */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Flashka - Learn faster with AI Flashcards!"
      />
      <meta
        name="twitter:description"
        content="Flashka helps you learn by automatically generating flashcards and aiding you in the learning process."
      />
      <meta name="twitter:creator" content="@SteDjokovic" />
      <meta name="twitter:image" content="/social-thumbnail.png" />
    </>
  );
}

export default App;

// Redirect to dashboard if user is auth-ed
const RootRedirect = () => {
  const { isSignedIn } = useAuth();

  if (isSignedIn) {
    return <Navigate to="/dashboard" replace />;
  }

  return <HomePage />;
};

const socialRedirects: Record<string, string> = {
  linkedin: "https://www.linkedin.com/company/flashka-ai",
  discord: "https://discord.gg/eafSXxrB3F",
  instagram: "https://www.instagram.com/flashka_ai",
};

export const SocialRedirectRoute = () => {
  const { platform } = useParams<{ platform: string }>();

  useEffect(() => {
    if (platform && platform in socialRedirects) {
      window.location.href = socialRedirects[platform];
    } else {
      // Redirect to homepage or show error
      window.location.href = "/";
    }
  }, [platform]);

  return <div>Redirecting...</div>;
};

let dataLayerSignupAssigned = false;
export const WelcomeRedirect = ({
  type,
}: {
  type: "welcome" | "welcome-back";
}) => {
  const navigateTo = useNavigate();
  useEffect(() => {
    // Push sign-up event to dataLayer
    if (!dataLayerSignupAssigned) {
      if (typeof window !== "undefined" && window.dataLayer) {
        dataLayerSignupAssigned = true;
        window.dataLayer.push({
          event: type === "welcome" ? "sign-up" : "sign-in",
        });
      } else {
        console.warn("dataLayer is not defined on window");
      }
    }
    navigateTo("/dashboard");
  }, []);

  return <div></div>;
};

interface ProtectedRouteProps {
  children: React.ReactNode;
  redirectTo?: string;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isSignedIn, isLoaded } = useAuth();

  if (!isSignedIn) {
    return (
      <div className="w-full h-full flex justify-center items-center mt-10">
        <SignIn
          redirectUrl="/welcome-back"
          afterSignInUrl="/welcome-back"
          afterSignUpUrl="/welcome"
          // signInFallbackRedirectUrl="/welcome-back"
          // signInForceRedirectUrl="/welcome-back"
          fallbackRedirectUrl="/welcome-back"
          signUpFallbackRedirectUrl="/welcome"
          signUpForceRedirectUrl="/welcome"
        />
      </div>
    );
  }

  return (
    <>
      <div
        className={cn(
          "w-full h-full flex justify-center items-center mt-10",
          !isLoaded ? "" : "hidden"
        )}
      >
        {!isLoaded ? (
          <DotLottieReact
            src="https://lottie.host/22bd1c95-01ac-4ae1-b928-d2cef47779d3/d5DqnmxmCw.lottie"
            autoplay
            loop
            className="max-h-64"
          />
        ) : (
          <></>
        )}
      </div>
      <div className={cn("h-full w-full", !isLoaded ? "hidden" : "")}>
        {children}
      </div>
    </>
  );
};

export function useAuthWithMock() {
  // State for mock values
  const [mockIsLoaded, setMockIsLoaded] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      // Simulate loading state
      setMockIsLoaded(false);

      setTimeout(() => {
        setMockIsLoaded(true);
      }, 2000);
    }, 2000);

    return () => clearInterval(interval);
  }, [mockIsLoaded]);

  return mockIsLoaded;
}
