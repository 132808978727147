// src/contexts/metadata-context.tsx
import { createContext, useState, useContext, ReactNode } from "react";

// Define the structure of your metadata based on your example
export interface Metadata {
  title?: string;
  description?: string;
  twitter?: {
    card?: string;
    title?: string;
    description?: string;
    creator?: string;
    image?: string; // Added for consistency
  };
  metadataBase?: URL | string; // Allow string for easier usage
  openGraph?: {
    type?: string; // Common OG type
    title?: string;
    description?: string;
    images?: string[];
    url?: string | URL; // Common OG url
  };
  // Add other meta tags as needed (e.g., keywords, author)
}

interface MetadataContextProps {
  metadata: Metadata;
  setMetadata: (newMetadata: Partial<Metadata>) => void;
}

// Create the context with a default value (can be empty or initial defaults)
const MetadataContext = createContext<MetadataContextProps | undefined>(
  undefined
);

// Define the default metadata
const defaultMetadata: Metadata = {
  title: "Flashka - Learn faster with AI Flashcards!",
  description:
    "Flashka helps you learn by automatically generating flashcards and aiding you in the learning process.",
  twitter: {
    card: "summary_large_image", // Default twitter card type
    title: "Flashka - Learn faster with AI Flashcards!",
    description:
      "Flashka helps you learn by automatically generating flashcards and aiding you in the learning process.",
    creator: "@SteDjokovic",
    image: "/social-thumbnail.png", // Consistent image
  },
  metadataBase: new URL("https://flashka.ai/"), // Use actual base URL logic if needed
  openGraph: {
    type: "website",
    title: "Flashka - Learn faster with AI Flashcards!",
    description:
      "Flashka helps you learn by automatically generating flashcards and aiding you in the learning process.",
    images: ["/social-thumbnail.png"],
    url: "https://flashka.ai/", // Use actual base URL logic if needed
  },
};

// Create the provider component
export const Metadata = ({ children }: { children: ReactNode }) => {
  const [metadata, setMetadataState] = useState<Metadata>(defaultMetadata);

  // Function to merge new metadata with existing, allowing partial updates
  const setMetadata = (newMetadata: Partial<Metadata>) => {
    setMetadataState((prevMetadata) => {
      // Deep merge for nested objects like twitter and openGraph
      const updatedMetadata = { ...prevMetadata };
      if (newMetadata.title !== undefined)
        updatedMetadata.title = newMetadata.title;
      if (newMetadata.description !== undefined)
        updatedMetadata.description = newMetadata.description;
      if (newMetadata.metadataBase !== undefined)
        updatedMetadata.metadataBase = newMetadata.metadataBase;

      if (newMetadata.twitter) {
        updatedMetadata.twitter = {
          ...prevMetadata.twitter,
          ...newMetadata.twitter,
        };
      }
      if (newMetadata.openGraph) {
        updatedMetadata.openGraph = {
          ...prevMetadata.openGraph,
          ...newMetadata.openGraph,
        };
      }
      return updatedMetadata;
    });
  };

  return (
    <MetadataContext.Provider value={{ metadata, setMetadata }}>
      <MetadataRenderer />
      {children}
    </MetadataContext.Provider>
  );
};

export const MetadataRenderer = () => {
  const { metadata } = useMetadata();
  const baseUrl = metadata.metadataBase?.toString() || "";

  // Helper to construct absolute URLs
  const absoluteUrl = (path?: string) => {
    if (!path) return undefined;
    try {
      // If it's already an absolute URL, return it
      new URL(path);
      return path;
    } catch (_) {
      // Otherwise, construct it using the base URL
      // Ensure no double slashes if path starts with / and baseUrl ends with /
      return `${baseUrl.replace(/\/$/, "")}${
        path.startsWith("/") ? "" : "/"
      }${path}`;
    }
  };

  return (
    <>
      {/* Basic metadata */}
      {metadata.title && <title>{metadata.title}</title>}
      {metadata.description && (
        <meta name="description" content={metadata.description} />
      )}

      {/* Base URL - Handled via absoluteUrl helper now, but could add <base> if needed */}
      {/* {baseUrl && <base href={baseUrl} />} */}

      {/* OpenGraph metadata */}
      {metadata.openGraph?.type && (
        <meta property="og:type" content={metadata.openGraph.type} />
      )}
      {metadata.openGraph?.title && (
        <meta property="og:title" content={metadata.openGraph.title} />
      )}
      {metadata.openGraph?.description && (
        <meta
          property="og:description"
          content={metadata.openGraph.description}
        />
      )}
      {metadata.openGraph?.url && (
        <meta
          property="og:url"
          content={absoluteUrl(metadata.openGraph.url.toString())}
        />
      )}
      {metadata.openGraph?.images?.map((img, index) => (
        <meta
          property="og:image"
          content={absoluteUrl(img)}
          key={`og-image-${index}`}
        />
      ))}

      {/* Twitter metadata */}
      {metadata.twitter?.card && (
        <meta name="twitter:card" content={metadata.twitter.card} />
      )}
      {metadata.twitter?.title && (
        <meta name="twitter:title" content={metadata.twitter.title} />
      )}
      {metadata.twitter?.description && (
        <meta
          name="twitter:description"
          content={metadata.twitter.description}
        />
      )}
      {metadata.twitter?.creator && (
        <meta name="twitter:creator" content={metadata.twitter.creator} />
      )}
      {metadata.twitter?.image && (
        <meta
          name="twitter:image"
          content={absoluteUrl(metadata.twitter.image)}
        />
      )}

      {/* Add other meta tags here */}
    </>
  );
};

// Create a custom hook for easy access to the context
export const useMetadata = (): MetadataContextProps => {
  const context = useContext(MetadataContext);
  if (context === undefined) {
    throw new Error("useMetadata must be used within a MetadataProvider");
  }
  return context;
};
